import _find from 'lodash/find';

import dataa from '../data/data.json';
import datas from '../data/data-speakers.json';

export const COLORS = [
  {
    textColor: `#00485E`,
    bgColor: `#FFFFFF`
  },
  {
    textColor: `#FFFFFF`,
    bgColor: `#00485E`
  },
  {
    textColor: `#00485E`,
    bgColor: `#F4F6F7`
  },
  {
    textColor: `#00485E`,
    bgColor: `#E2EBEC`
  }
];

export function smoothScrollBackToTop (){
  const targetPosition = 0;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  const duration = 750;
  let start = null;
  window.requestAnimationFrame(step);
  function step(timestamp) {
    if (!start) start = timestamp;
    const progress = timestamp - start;
    window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
    if (progress < duration) window.requestAnimationFrame(step);
  }
}

function easeInOutCubic(t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t*t + b;
  t -= 2;
  return c/2*(t*t*t + 2) + b;
};




// date


export function getStringDate(data){
    let weekday = [`Sunday`,`Monday`,`Tuesday`,`Wednesday`,`Thursday`,`Friday`,`Saturday`]
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let day = weekday[data.getDay()];
    let month = monthNames[data.getMonth()]
    let date = data.getDate();
    return day + `, ` + month + ` ` + date
  }

export function getDate(str){
    var b = str.split(/\D/);
    let date = new Date(b[0], b[1]-1, b[2]);
    return date
  }

export function getSpeakers(speakersId){
    let speakers = [];
    for(let i=0; i<speakersId.length; i++){
      let el = _find(datas.speakers, function (item){
        return parseInt(item.id) === speakersId[i];
      })
      speakers.push(el)
    }
    return speakers
  }


export function getHero(){
    let sessions = dataa.hero;
    for(let i=0; i<sessions.length; i++){
      if (sessions[i].hero === true) {
          return sessions[i];
      }
    }
    return sessions[0]
  }


export function getCarousel(){
    let carousel = dataa.carousel;
    let activeCarousel = [];
    for(let i=0; i<carousel.length; i++){
      if(carousel[i].carousel !== -1){
        // activeCarousel.push(carousel[i])
        activeCarousel[carousel[i].carousel] = carousel[i]
      }
    }

    // for(let j=0; j<activeCarousel.length; j++){
    //   let speakersId = activeCarousel[j].speakers
    //   activeCarousel[j].speakersName = getSpeakers(speakersId);
    // }

    return activeCarousel
  }