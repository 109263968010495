import React from 'react'
import "./player.css"
import { useQueryParam, StringParam } from "use-query-params";

const Player = (props) => {

  const [vid, setVid] = useQueryParam('video', StringParam);


  return (
      <div className={`modal${(props.UUID !== -1)?` d-block`:` d-none`}`}>
        <div className={`player`}>
          {
            (props.UUID!==-1) ?
              <div className={`white-window`} dangerouslySetInnerHTML={{ __html: `<iframe src=${'https://www.brighttalk.com/service/player/en-US/theme/default/channel/18696/webcast/'+props.UUID+'/play?showChannelList=false'} width="100%" height="100%"></iframe>` }} />
                :
              <div/>
          }
        </div>

        <a href={"/#"} onClick={
          (e)=>{
            e.preventDefault();
            setVid(undefined);
            props.setShow(-1);

                            if(window.analytics){
                              window.analytics('send', 
                                {
                                  hitType: 'event',
                                  eventCategory: 'player',
                                  eventAction: 'click',
                                  eventLabel: 'close'
                                }
                              );
                            }

          }
        } data-linkloc="" className={`close-modal`}>
          <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-x d-none d-sm-block" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-x d-block d-sm-none" fill="black" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </a>
      </div>
  )
}
export default Player